import {
  getValueFromObject,
  isExistAndPositiveInteger,
  accusative
} from './common';
import { seoQuery } from '../../queries';
import { makeHostSum, normalizePath } from './url';

const rangeTranslation = (from, to, unit, i18n) => {
  let result;
  if (isExistAndPositiveInteger(from) && isExistAndPositiveInteger(to)) {
    result = i18n.t('search.search_title_service.from_and_to', {
      from,
      to,
      unit
    });
  } else if (isExistAndPositiveInteger(from)) {
    result = i18n.t('search.search_title_service.from', {
      from,
      unit
    });
  } else if (isExistAndPositiveInteger(to)) {
    result = i18n.t('search.search_title_service.to', {
      to,
      unit
    });
  }

  return result;
};

export const getSeoData = async (apolloClient, asPath, req) => {
  if (!apolloClient) {
    return {};
  }
  const newAsPath = normalizePath(asPath);
  const hostSum = makeHostSum({ asPath: newAsPath, req });
  const {
    data: { seo }
  } = await apolloClient.query({
    query: seoQuery(newAsPath),
    variables: {
      path: newAsPath
    }
  });

  return {
    title: seo?.title,
    description: seo?.description,
    h1: seo?.h1,
    asPath: newAsPath,
    hostSum
  };
};

export const createSeoConfig = (seoData, fbAppId, i18n) => {
  if (!seoData || Object.keys(seoData).length === 0) {
    return false;
  }

  const title = seoData.title || i18n.t('default_title');
  const description = seoData.description
    ? seoData.description
    : i18n.t('default_description');
  const { currentUrl, path } = seoData.hostSum;

  return {
    title,
    description,
    openGraph: {
      url: path === '/' ? currentUrl : null
    },
    facebook: {
      appId: fbAppId
    }
  };
};

export class SearchItemsTitleService {
  constructor(data, filter, i18n) {
    this.data = data;
    this.filter = filter;
    this.i18n = i18n;
  }

  action = () => {
    if (typeof this.filter.leased === 'undefined') return null;
    const type = this.filter.leased ? 'rent' : 'buy';

    return this.i18n.t(`search.search_title_service.${type}`);
  };

  category = () => {
    if (!isExistAndPositiveInteger(this.filter.categoryId)) return null;
    return accusative(
      getValueFromObject(
        this.data.categories,
        this.filter.categoryId,
        'name'
      ).toLowerCase(),
      this.i18n.language
    );
  };

  rooms = () => {
    if (!this.filter.roomIds || this.filter.roomIds.length > 1) {
      return '';
    }
    return this.i18n.t('search.form.room_count', {
      postProcess: 'interval',
      count: this.filter.roomIds[0] !== '5+' ? this.filter.roomIds[0] : '5'
    });
  };

  city = () => {
    return getValueFromObject(this.data.cities, this.filter.cityId, 'name');
  };

  area = () => {
    const units = this.i18n.t('search.search_title_service.units.metres');

    return rangeTranslation(
      this.filter.areaFrom,
      this.filter.areaTo,
      units,
      this.i18n
    );
  };

  price = () => {
    return rangeTranslation(
      this.filter.priceFrom,
      this.filter.priceTo,
      'AZN',
      this.i18n
    );
  };

  location = () => {
    const locationsNamesArray =
      this.filter.locationIds &&
      this.filter.locationIds.map(item => {
        return getValueFromObject(this.data.locations, item, 'name');
      });

    if (!locationsNamesArray || locationsNamesArray.length > 1) {
      return null;
    }

    return locationsNamesArray[0];
  };

  getTitle = () => {
    const title = this.i18n
      .t('search.search_title_service.format', {
        action: this.action(),
        category: this.category(),
        rooms: this.rooms(),
        city: this.city(),
        area: this.area(),
        price: this.price(),
        location: this.location()
      })
      .split()
      .join(' ')
      .replace(/\s,/g, ',')
      .replace(/,+,*/g, ',')
      .replace(/,\s$/, '')
      .replace(/\s, /, '')
      .trim();
    return `${title.replace(/^./, title[0].toUpperCase())} - Bina.az`;
  };
}

export class SearchItemsH1Service extends SearchItemsTitleService {
  price = () => {
    return (
      (!(this.filter.locationIds && this.filter.locationIds.length > 1) &&
        rangeTranslation(
          this.filter.priceFrom,
          this.filter.priceTo,
          'AZN',
          this.i18n
        )) ||
      ''
    );
  };

  location = () => {
    const locationsNamesArray =
      this.filter.locationIds &&
      this.filter.locationIds.map(item => {
        return getValueFromObject(this.data.locations, item, 'name');
      });

    return locationsNamesArray ? locationsNamesArray.join(', ') : null;
  };

  getH1 = () =>
    this.i18n
      .t('search.search_title_service.format', {
        action: this.action(),
        category: this.category(),
        rooms: this.rooms(),
        city: this.city(),
        area: this.area(),
        price: this.price(),
        location: this.location()
      })
      .split()
      .join(' ')
      .replace(/\s,/g, ',')
      .replace(/,+,*/g, ',')
      .replace(/,\s$/, '')
      .replace(/\s, /, '');
}
