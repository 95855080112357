import { gql } from '@apollo/client';
import genericFields from './genericFields';

const agencyItemsFragmentQuery = gql`
  fragment agencyItemFields on Item {
    ${genericFields}
    photos(limit: 1) {
      thumbnail
    }
  }
`;

export const agenciesRowQuery = gql`
  query AgenciesRowQuery {
    agencies(limit: 8, sort: RANDOM) {
      items(limit: 1, sort: RANDOM) {
        ...agencyItemFields
      }
    }
  }
  ${agencyItemsFragmentQuery}
`;

export const agenciesQuery = gql`
  query AgenciesQuery {
    agencies(sort: RANDOM) {
      id
      name
      logoUrl
      description
      itemsCount
      path
    }
  }
`;
