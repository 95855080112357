const genericFields = `
  id
  area {
    value
    units
  }
  floor
  floors
  city {
    id
    name
  }
  location {
    id
    name
    fullName
  }
  hasMortgage
  price {
    value
    currency
  }
  paidDaily
  rooms
  hasBillOfSale
  vipped
  featured
  updatedAt
  path
  photosCount
`;

export default genericFields;
